<template>
    <div>
        <q-dialog v-model="creation_dialog" persistent>
            <PaymentCreationForm @success="onPaymentCreation"/>
        </q-dialog>
        <q-table
            :loading="loading"
            :rows="rows"
            :columns="columns"
            row-key="uuid"
            flat
            :rows-per-page-options="[10, 25]"
        >
            <template v-slot:top="props">
                <q-space />
                <q-btn icon="refresh" @click="fetch" />
                <q-btn icon="add" @click="creation_dialog = true" class="q-ml-md" color="teal" />
            </template>
        </q-table>
    </div>
</template>

<script>
import PaymentCreationForm from "@/components/PaymentCreationForm";
import Payment from "@/models/user/payment.js";

export default {
    name: 'PaymentAdmin',
    components: {
        PaymentCreationForm
    },
    data () {
        return {
            subscribedOnly: false,
            rows: [],
            columns: [{
                field: "user",
                name: "user",
                label: "Utilisateur",
                format: (val, row) => val.toString()
            }, {
                field: "created_at",
                name: "created_at",
                label: "Date",
                format: (val, row) => val.calendar(),
                sort: (a, b, rowA, rowB) => b.format("X") - a.format("X"),
                sortable: true
            }, {
                field: "description",
                name: "description",
                label: "Description"
            }, {
                field: "amount",
                name: "amount",
                label: "Montant (hors frais)",
                format: (val, row) => val.toFixed(2) + " €"
            }],
            loading: true,
            creation_dialog: false
        };
    },
    mounted () {
        this.fetch();
    },
    methods: {
        async fetch () {
            this.loading = true;
            this.rows = await Payment.filter([], true);
            this.loading = false;
        }
    }
}
</script>
