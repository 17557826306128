<template>
    <q-card>
        <q-card-section class="row items-center q-pb-none">
            <div class="text-h6">Ajouter un paiement</div>
            <q-space />
            <q-btn icon="close" flat round dense v-close-popup />
        </q-card-section>
        <q-card-section>
        </q-card-section>
        <q-separator />
        <q-card-section class="text-right">
            <q-btn :disabled="is_loading" @click="create_payment" color="primary" label="Valider" />
        </q-card-section>
        <q-inner-loading :showing="is_loading">
            <q-spinner-gears size="50px" color="primary" />
        </q-inner-loading>
    </q-card>
</template>

<style scoped>
.priceCard {
    max-width: 250px;
    width: 100%;
    min-height: 350px;
}
</style>

<script>
import moment from "moment";
import Payment from "@/models/user/payment.js";
export default {
    name: 'PaymentCreationForm',
    data () {
        return {
            is_loading: false,
            payment: new Payment({})
        };
    },
    methods: {
        async create_payment () {
            this.is_loading = true;
            try {
                await this.payment.update();

                this.$q.notify({
                    type: "positive",
                    message: `Opération réalisée avec succès!`
                });

                this.$emit("success");
            } catch (e) {
                console.error(e);
            }

            this.is_loading = false;

        }
    }
}
</script>
